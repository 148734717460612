
#google_translate_element select{ 
  background:#707023;
  /* background:transparent; */
  outline: none;
  color:white;
  border: none;
  border-radius:3px;
  padding:6px 8px
}
#google_translate_element select > option {
color: black;
}
#google_translate_element select > option:hover{
background-color: #a827dd;
}
.goog-logo-link{
display:none!important;
}
.goog-te-gadget{
color:transparent!important;
} 
.goog-te-banner-frame{
display:none !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd{
/* display:none; */
}

#goog-gt-tt, .goog-te-balloon-frame{display: none !important;}
.goog-text-highlight { background: none !important; box-shadow: none !important;}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
display: none !important;
} 
body{
top:0 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-span {
  background-color: white;
  padding-left: 3.8rem;
  /* padding-top: 3rem; */
  /* color: rgb(97, 141, 8); */
  color: black;
  text-transform: capitalize;
}
@media (max-width: 707px) {
  .nav-span {
    padding-left: 0;
  }
}


